@import "src/sass/fonts.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/breakpoints.module.scss";
@import "src/sass/colors.scss";

.currencies {
    $b-root: &;

    @include flex($gap: 48px);

    @include lg-breakpoint {
        flex-direction: column;
    }

    label {
        @include fonts(15px, 700);
    }

    &__overview {
        @include flex(column, $gap: 16px);
    }

    &__default-currency,
    &__secondary-currency {
        @include flex(row, space-between, center);

        width: 380px;

        @include md-breakpoint {
            @include flex(column);

            width: fit-content;
        }

        div {
            border: 1px solid $color-gray-4f;
            border-radius: 8px;
            padding: 16px;
            color: $color-gray-4f;

            font-size: 16px;
            width: 200px;
            box-sizing: border-box;

            @include sm-breakpoint {
                width: 150px;
            }
        }
        select {
            border: none;
            color: $color-gray-4f;

            font-size: 15px;
            margin: 0;

            &:focus,
            &:hover {
                outline: none;
            }
        }
    }

    &__secondary-currency {
        div {
            padding: 6px 16px;
        }
        #{$b-root}__secondary-loading-wrapper {
            border: none;
            height: 54px;
            @include flex($justify-content: center, $align-items: center);

            #{$b-root}__secondary-loading {
                @include size(20px, 20px);
                padding: 0;
                border-radius: 50%;
                border: 2px solid rgba(0, 0, 0, 0.25);
                border-top: 2px solid $color-black;
            }
        }
    }

    &__exchange-rate {
        @include flex(column, $justify-content: center, $align-items: center);

        padding: 0 16px;

        @include lg-breakpoint {
            padding: 0;
            @include flex(column, $justify-content: flex-start, $align-items: flex-start);
        }

        #{$b-root}__exchange-rate-title {
            @include flex($gap: 10px, $align-items: flex-start);

            label {
                margin-top: 8px;
            }

            button {
                transition: transform 0.2s;
                padding: 0;
                margin: 0;
                min-height: unset;

                &:hover {
                    transform: scale(1.1);
                    svg {
                        fill: $royal-peacock;
                    }
                }
            }
        }
        #{$b-root}__exchange-rate-content {
            @include fonts(14px, 600);

            margin-top: 12px;
            color: $color-gray-4f;

            border-collapse: collapse;

            th {
                border-bottom: 2px solid $background-secondary;
            }

            td,
            th {
                padding: 12px 16px;
                text-align: center;

                text-overflow: ellipsis;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                min-width: 70px;

                @include xs-breakpoint {
                    max-width: 50px;
                    min-width: none;
                }
            }
        }
    }

    &__modal {
        text-align: center;

        .modal__content {
            width: 580px;
            @include flex(column, $gap: 16px);
        }
        #{$b-root}__modal-title {
            @include fonts(20px, 700, $line-height: 30px);
        }

        #{$b-root}__modal-description {
            @include fonts(15px, 500, $line-height: 21px);
            color: $color-gray-4f;
        }
    }
}
