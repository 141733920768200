@import 'src/sass/colors.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';

.ne-employee-request-vacation-form {
  @include flex(column);
  height: 100%;
  width: 100%;
  gap: 2rem;

  .textarea-container {
    textarea {
      border: 1px solid $color-gray-33;
      border-radius: 8px;
      padding: 0.25rem;
    }
  }

  &__button {
    @include fonts(14px, 700, normal, 21px);

    padding: 12px 24px;
    margin: auto 0 0 auto;
  }
}
