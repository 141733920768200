@import 'src/sass/flex.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/size.module.scss';

.ne-table-pagination {
  display: flex;
  list-style-type: none;
  @include flex(row, center);
  gap: 1rem;

  .ncoded-employee-button {
    background-color: $color-white;
    color: $color-black;
    padding: 4px 8px;
    min-height: auto;
    @include size(2rem);

    &:hover:not(:disabled),
    &.selected:not(:disabled) {
      background-color: $color-black;
      color: $color-white;
    }
  }
  .arrow-left,
  .arrow-right {
    &:hover:not(:disabled) {
      svg {
        path {
          stroke: $color-white;
        }
      }
    }
    padding: 0;
  }

  .arrow-right {
    scale: -1;
  }
}
