@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/colors.scss';
@import 'src/sass/breakpoints.module.scss';

.vacation-request {
  @include flex(column, center, space-between, 30px);
  @include size(629px, fit-content);

  h1 {
    @include fonts(32px, 700, normal, 39px);
    @include flex(row, center, center, 0.5rem);
    align-self: center;

    div {
      @include flex(row, center, center);
    }

    .approved-icon,
    .fulfilled-icon {
      svg {
        color: $mountain-meadow;
        @include size(2.5rem);
      }
    }

    .denied-icon {
      svg {
        color: $venetian-red;
        @include size(1.5rem);
      }
    }
  }

  &__content {
    @include flex(column, center);
    gap: 2rem;
    padding: 3rem;
    border: 1px solid $color-gray-d9;
    border-radius: 1rem;
    background-color: $color-gray-f2;
    min-width: 15rem;
  }

  &__subsection {
    p {
      font-size: 14px;
      color: $color-gray;
      padding-bottom: 0.3rem;
      b {
        font-size: 18px;
        color: $color-black;
      }
    }

    .description {
      font-size: 16px;
      text-align: justify;
      color: $color-gray-26;
      label {
        color: $color-gray-82;
      }
      p {
        color: $color-gray-4f;
      }
    }

    a {
      width: 6rem;
      @include flex(row, flex-start, center, 0.5rem);
      background-color: $royal-peacock;
      border-radius: 0.5rem;
      padding: 0.5rem 0.8rem 0.5rem 0.8rem;
      fill: $color-white;
      color: $color-white;
      text-decoration: none;
      svg {
        @include size(1.5rem);
      }

      &:hover {
        background-color: $mountain-meadow;
      }
    }
  }

  &__dates {
    @include flex(row, flex-start, center);
    gap: 1rem;
    span {
      font-size: 22px;
      align-self: flex-end;
      padding-bottom: 0.2rem;
    }
  }

  &__buttons {
    @include flex(row, center, space-between);
    gap: 2rem;
    align-self: center;
    margin-top: 2rem;
    Button {
      width: 8rem;
    }

    &--reject {
      background-color: $color-white;
      border: 1px solid $venetian-red;
      color: $venetian-red;
    }

    &--approve {
      background-color: $mountain-meadow;
    }

    &--disabled {
      cursor: not-allowed;
      pointer-events: none;
      box-shadow: none;
    }
  }

  &__error-msg {
    padding-top: 30px;

    color: $color-red;
    text-align: center;
  }

  &__upload {
    @include flex(column, center, center);
    gap: 2rem;
    align-self: center;
  }
}
