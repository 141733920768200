@import 'src/sass/animation/fade-in.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/colors.scss';

.ne-datepicker {
  @include flex(column);

  label {
    @include fonts(14px, 600);
    color: $color-gray-4f;
  }
  &--disabled {
    .react-datepicker__input-container {
      opacity: 0.5;
    }
  }
  .react-datepicker__input-container {
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
    border-bottom: 2px solid $color-gray-33;
    input {
      @include size(100%, 40px);
      @include fonts(16px);

      border: none;
      background-color: transparent;

      caret-color: transparent;

      padding-block: 0;
      padding-inline: 0;

      &:focus {
        outline: none;
        border: none;
      }
    }
  }
}
