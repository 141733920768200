@import "src/sass/fonts.module.scss";
@import "src/sass/flex.module.scss";
@import "src/sass/size.module.scss";
@import "src/sass/colors.scss";
@import "src/sass/breakpoints.module.scss";

.ne-timeoffs-table {
  &__filters {
    @include flex(row, space-between, center);
    gap: 2rem;

    .ne-input {
      max-width: 400px;
      min-width: 30%;
      &__prefix {
        margin-right: 0.5rem;
        @include flex(row, space-between, center);
      }
      svg {
        fill: currentColor;
        width: 100%;
        height: 100%;
        max-width: 1.5rem;
        max-height: 1.5rem;
      }
    }

    @include sm-breakpoint {
      @include flex(column, center, center);
      .ne-input {
        min-width: 15rem;
      }
    }
  }

  .nce-table {
    &__employee {
      width: 14rem;
      cursor: pointer;
    }

    Button {
      @include size(1.5rem);
      background-color: transparent;
      color: $color-gray-77;
      padding: 0;
      svg {
        @include size(1.5rem);
      }
      &:hover {
        color: $royal-peacock;
      }
      transition-duration: 0.5s;
    }

    .ncoded-employee-button--disabled {
      opacity: 0.5;

      &:hover {
        color: $color-gray-77;
      }
    }

    &__requested_on,
    &__start {
      p {
        @include flex(row, flex-start, center, 1rem);
      }
      svg {
        @include size(1.5rem);
        color: $color-black;
      }
    }
  }

  &__data {
    @include flex(column, stretch, stretch, 2rem);
    gap: 2rem;
  }

  &__table-container {
    table {
      overflow-x: scroll;
    }
  }

  @include lg-breakpoint {
    gap: 1rem;
  }

  @include sm-breakpoint {
    &__table-container {
      overflow-x: auto;
    }
  }

  @include sm-breakpoint {
    padding-top: 5rem;

    &__table-container {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 0.8rem; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: $color-gray-f1; /* color of the tracking area */
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-gray-88; /* color of the scroll thumb */
        border-radius: 10px;
        border: 3px solid $color-gray-f1;
      }

      .nce-table {
        tr {
          td {
            padding: 0;
          }
        }

        &__start {
          min-width: 5rem;
        }
      }
    }

    .ne-section-block {
      gap: 1rem;
    }

    .ne-table-pagination {
      gap: 0.3rem;
    }
  }

  .sort-icon--1 {
    svg {
      transform: scale(-1);
    }
  }
}
