@import 'src/sass/colors.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/fonts.module.scss';

.employee-request-vacation-modal {
  &__container {
    @include flex(column, undefined, center, 20px);
    @include size(630px, 550px);

    $root: &;
    text-align: justify;
    color: $color-gray-82;

    &__title {
      @include fonts(32px, 700, normal, 39px);
      color: $color-gray-26;
      margin-top: 12px;
    }

    &__notiffication {
      text-align-last: center;
      margin: 20px 0 40px;
    }

    @include sm-breakpoint {
      width: auto;
    }
  }
}
