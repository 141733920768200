@import 'src/sass/colors.scss';
@import 'src/sass/fonts.module.scss';
@import 'src/sass/breakpoints.module.scss';
@import 'src/sass/flex.module.scss';
@import 'src/sass/size.module.scss';

.vacations-table {
  &__title {
    height: 90px;
  }

  &__download-icon {
    @include size(20px);

    opacity: 0.2;
  }

  table {
    tr {
      grid-template-columns: repeat(6, 1fr);
    }

    td,
    th {
      &:last-child {
        justify-content: center;
      }
      &:first-child {
        justify-content: flex-start;
      }
    }

    td.vacations-table__status {
      font-weight: 400;
    }
  }
}
